import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import getWellDataTimeRange from '@/graphql/cleanData/queries/getWellDataTimeRange.graphql';
import getReferenceTimeRange from '@/graphql/deferment/queries/getReferenceTimeRange.graphql';
import listNodeEventsV2 from '@/graphql/cleanData/queries/listNodeEventsV2.graphql';
import getProcedureResponsesByNodeId from '@/graphql/procedures/queries/getProcedureResponsesByNodeId.graphql';
import getWellCommentHistory from '@/graphql/workflow/queries/getWellCommentHistory.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import defermentApolloClient from '@/lib/appsync/deferment';
import proceduresApolloClient from '@/lib/appsync/procedures';
import workflowApolloClient from '@/lib/appsync/workflow';


@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqProductionDataChartModule',
  store,
})
class TasqProductionDataChartModule extends VuexModule {
  productionData: any = {
    dataset: [],
    time: [],
    loaded: false,
  };

  productionDataDefermentLabeling: any = {
    dataset: [],
    time: [],
    loaded: false,
  };


  productionDataDict: any = {
	date: [],
	gas_rate: [],
	nodeid: '',
	oil_rate: [],
	water_rate: []
  }

  wellHistory: any = [];


  @Mutation
  resetProductionDataDict(): void {
    this.productionDataDict = {
		date: [],
		gas_rate: [],
		nodeid: '',
		oil_rate: [],
		water_rate: []
  	}
  }


  @Mutation
  resetProductionData(): void {
    this.productionData = {
      data: [],
      time: [],
      loaded: false,
    };
    this.wellHistory = [];
  }

  @Mutation
  setProductionDataDict(data): void {
    this.productionDataDict = data;
  }


  @Mutation
  setProductionData(data): void {
    this.productionData = data;
  }

  @Mutation
  setProductionDataDefermentLabeling(data): void {
    this.productionDataDefermentLabeling = data;
  }
  


  @Mutation
  resetProductionDataDefermentLabeling(): void {
    this.productionDataDefermentLabeling = {
		dataset: [],
		time: [],
		loaded: false,
	  };
  }
  

  @Mutation
  setWellHistoryData(data): void {
    this.wellHistory = data;
  }


  



  @Action
  async queryProductionData(daysLength): Promise<{}> {


    const fromTime = DateTime.local().minus({ days: daysLength }).toISO();
    const toTime = DateTime.local().toISO();

    const {
      data: {
        get_well_test_data_time_range_v2: {
          date: time = [],
          oil_rate: oilData = [],
          water_rate: waterData = [],
          gas_rate: gasData = [],
        },
      },
    } = await cleanDataApolloClient.query({
      query: getWellDataTimeRange,
      variables: {
        nodeid: tasqsListModule.activeTasq?.wellName,
        from_date: fromTime,
        to_date: toTime,
        operator: getConfigEnv('OPERATOR_PASCALED'),
      },
    });

    const {
      data: {
        get_reference_prod_time_range: rawReferenceData,
      },
    } = await defermentApolloClient.query({
      query: getReferenceTimeRange,
      variables: {
        nodeid: tasqsListModule.activeTasq?.wellName,
        operator: getConfigEnv('OPERATOR_PASCALED'),
        description: 'gas_rate',
        from_time: fromTime,
        to_time: toTime,
      },
    });

    
	return {
		rawReferenceData: rawReferenceData,
		referenceDataset: rawReferenceData.dataset,
		time: time,
		oilData: oilData,
		waterData: waterData,
		gasData: gasData
	}


  }





  @Action
  async getProductionDataDefermentLabeling(daysLength = 90): Promise<void> {
	// daysLength = 100
    if (!tasqsListModule.activeTasq?.wellName) return;


	var response = await this.queryProductionData(daysLength);

	var rawReferenceData = response["rawReferenceData"]
	var time = response["time"]
	var gasData = response["gasData"]
	var oilData = response["oilData"]
	var waterData = response["waterData"]
	const referenceDataset = JSON.parse(response["referenceDataset"]);

    const upperData = referenceDataset.Value[rawReferenceData.description.indexOf('upper_thresh')];
    const mainData = referenceDataset.Value[rawReferenceData.description.indexOf('Reference')];
    const lowerData = referenceDataset.Value[rawReferenceData.description.indexOf('lower_thresh')];

	// this.setProductionDataDict({
	// 	date: time,
	// 	gas_rate: gasData,
	// 	nodeid: tasqsListModule.activeTasq?.wellName,
	// 	oil_rate: oilData,
	// 	water_rate: waterData
	// })

    this.setProductionDataDefermentLabeling({
      data: [
        oilData,
        waterData,
        gasData,
        upperData,
        mainData,
        lowerData,
      ],
      time,
      loaded: true,
    });
  }




  @Action
  async getProductionData(daysLength = 14): Promise<void> {
	// daysLength = 100
    if (!tasqsListModule.activeTasq?.wellName) return;
    this.resetProductionData();


	var response = await this.queryProductionData(daysLength);

	var rawReferenceData = response["rawReferenceData"]
	var time = response["time"]
	var gasData = response["gasData"]
	var oilData = response["oilData"]
	var waterData = response["waterData"]
	const referenceDataset = JSON.parse(response["referenceDataset"]);

    const upperData = referenceDataset.Value[rawReferenceData.description.indexOf('upper_thresh')];
    const mainData = referenceDataset.Value[rawReferenceData.description.indexOf('Reference')];
    const lowerData = referenceDataset.Value[rawReferenceData.description.indexOf('lower_thresh')];

	this.setProductionDataDict({
		date: time,
		gas_rate: gasData,
		nodeid: tasqsListModule.activeTasq?.wellName,
		oil_rate: oilData,
		water_rate: waterData
	})

    this.setProductionData({
      data: [
        oilData,
        waterData,
        gasData,
        upperData,
        mainData,
        lowerData,
      ],
      time,
      loaded: true,
    });
  }

  @Action
  async getWellHistory(daysLength = 14) {
    if (!tasqsListModule.activeTasq?.wellName) return;

    const activeTasq = tasqsListModule.activeTasq;

    const fromTime = DateTime.local().minus({ days: daysLength }).toISO();
    const toTime = DateTime.local().toISO();

    const {
      data: {
        list_node_events_v2: {
          dataset,
        },
      },
    } = await cleanDataApolloClient.query({
      query: listNodeEventsV2,
      variables: {
        nodeid: activeTasq.wellName,
        from_time: fromTime,
        to_time: toTime,
        operator: getConfigEnv('OPERATOR_LOWERCASED')
      },
    });

    const {
      data: {
        get_procedure_responses_by_node_id: {
          results: unparsedProcedures,
        },
      },
    } = await proceduresApolloClient.query({
      query: getProcedureResponsesByNodeId,
      variables: {
        input: {
          node_id: activeTasq.wellName,
          start_date: fromTime,
          end_date: toTime,
          operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });
    const parsedProceduresEvents = unparsedProcedures.map((i) => JSON.parse(i));

    const {
      data: {
        get_well_comment_history: {
          well_history: rawWellHistory,
        },
      },
    } = await workflowApolloClient.query({
      query: getWellCommentHistory,
      variables: {
        node_id: activeTasq.wellName,
        start_date: fromTime,
        end_date: toTime,
        operator: getConfigEnv('OPERATOR_LOWERCASED')
      },
    });

    const wellHistory = jsonParse(rawWellHistory?.[0] || null) || [];
    const comments = wellHistory.comments || [];
    let events = [] as any;

    events = dataset ? [...dataset, ...comments, ...parsedProceduresEvents].map((i, idx) => {
      if (i.response_data) {
        const name = i.response_data.user_response ? (i.response_data.user_response || {}).selection || ''
          : (i.response_data.UserResponse || {}).Selection || '';
        const failure = i.response_data.user_response ? i.response_data?.user_response?.selection_details.reason[0] || '' // eslint-disable-line
          : i.response_data?.UserResponse?.SelectionDetails?.Reason[0] || '';
        const yesResponse = name === 'YES';
        return {
          id: idx,
          type: 'Anomaly',
          failure,
          name,
          start: new Date(i.time),
          color: '#ffb01b',
          description: i.comment,
          iconColor: yesResponse ? 'green' : 'red',
          icon: yesResponse ? 'check_circle' : 'cancel',
        };
      }
      if (i.ProcedureID) {
        return {
          id: idx,
          start: new Date(`${i.CreatedTime}Z`),
          type: 'Procedure',
          color: '#8C8FB0',
          description: `Action: ${i.ResponseOption}`,
        };
      }
      const parsed = JSON.parse(i);
      const info = parsed._value || []; // eslint-disable-line
      const setPoint = info[0].Note === 'Setpoint Change';
      return {
        id: idx,
        start: new Date(parsed.time),
        type: setPoint ? 'Setpoint change' : 'Event',
        color: setPoint ? '#BD62FF' : '#0076FF',
        info,
        description: setPoint
          ? `Prior value: ${info[0].PriorValue.toFixed(1) || 'N/A'}, New value: ${info[0].NewValue.toFixed(1) || 'N/A'}` // eslint-disable-line
          : (info || []).map((e) => e.Note).join('. '),
        name: setPoint ? info[0].Description : 'XSPOC alarm',
      };
    }) : [];
    events = events.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());

    this.setWellHistoryData(events);
  }
}

export default getModule(TasqProductionDataChartModule);
